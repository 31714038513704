import React, { forwardRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
 import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import DownloadIcon from '@mui/icons-material/Download';
import XLSX from 'xlsx';
import Typography from "@material-ui/core/Typography";

const base64 = require('base-64');

const tableIcons = {
   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ color: "#f0e8ec" }}/>)
};

function Manager() {
  const [testersData, setTestersData] = useState([]);
  const [summary, setSummary] = useState([]);
  const [refreshTime, setRefreshTime] = useState([]);
  const tableRef = React.createRef();

    const summaryColumns = [
      { title: "TeamName", field: "teamName", },
      { title: "Manager", field: "manager"},
      { title: "OffQueue (mins)", field: "offqueueMinutes"},
      { title: "Onqueue (mins)", field: "onqueueMinutes"},
      { title: "NoSkills (mins)", field: "noSkillsMinutes" },
      { title: "Break (mins)", field: 'unavailableMinutes' },
      { title: "TimeLeft (mins)", field: "remainderOfTime" },
      { title: "Skills Assigned", field: "skillsAssigned" },
      { title: "Skills Closed", field: "skillsClosed" },
      { title: "AHTUtilized (mins)", field: "ahtUtilized" },
      { title: "Skills Queued", field: "skillsQueued" },
      { title: "AHTRequired (mins)", field: "ahtRequired" }

    ]

      const columns = [
      { title: "UserId", field: "testerId", },
      { title: "TeamName", field: "teamName"},
      { title: "Manager", field: "manager"},
      { title: "ConnectTime  ", field: "connectTime", sorting: false },
      { title: "Onqueue (mins)", field: "onqueueMinutes" },
      { title: "Offqueue  (mins)", field: 'offqueueMinutes' },
      { title: "NoSkills (mins)", field: "noSkillsMinutes" },
      { title: "Idle (mins)", field: "idleMinutes" },
      { title: "Break (mins)", field: "unavailableMinutes" },
      { title: "TimeLeft (mins)", field: "remainderOfTime" },
      { title: "Skills Assigned", field: "skillsAssigned" },
      { title: "Skills Closed", field: "skillsClosed" },
      { title: "AHTUtilized  (mins)", field: "ahtUtilized" },
      { title: "Skills Queued", field: "skillsQueued" },
      { title: "AHTRequired  (mins)", field: "ahtRequired" }
  
    ]
    useEffect(() => {
    console.log(process.env.REACT_APP_MAGNUS_BACKEND + "testersSkillsDataGrouped" +" is the url");
    fetch(process.env.REACT_APP_MAGNUS_BACKEND + "testersSkillsDataGrouped")
         .then(resp => resp.json())
         .then(resp => {
          resp.testersPaceData.forEach(function (paceData) {
            paceData.onqueueMinutes = Math.round(paceData.onqueueMinutes);
            paceData.offqueueMinutes = Math.round(paceData.offqueueMinutes);
            paceData.noSkillsMinutes = Math.round(paceData.noSkillsMinutes);
            paceData.idleMinutes = Math.round(paceData.idleMinutes);
            paceData.unavailableMinutes = Math.round(paceData.unavailableMinutes);
            paceData.remainderOfTime = Math.round(paceData.remainderOfTime);
        });

          setTestersData(resp.testersPaceData);
          setRefreshTime(resp.lastUpdated);
           console.log(resp.testersPaceData);
         })
         .catch(err => {
          setTestersData(null);
          setRefreshTime(null);
        })
         
   
       fetch(process.env.REACT_APP_MAGNUS_BACKEND + "getTestersStatusSummaryByManagers")
         .then(response => response.json())
         .then(response => {
           console.log("sumary response is "+response);

           response.forEach(function (paceData) {
            paceData.onqueueMinutes = Math.round(paceData.onqueueMinutes);
            paceData.offqueueMinutes = Math.round(paceData.offqueueMinutes);
            paceData.onqueueMinutes = Math.round(paceData.onqueueMinutes);
            paceData.noSkillsMinutes = Math.round(paceData.noSkillsMinutes);
            paceData.unavailableMinutes = Math.round(paceData.unavailableMinutes);
            paceData.remainderOfTime = Math.round(paceData.remainderOfTime);
            paceData.ahtUtilized = Math.round(paceData.ahtUtilized);
            paceData.ahtRequired = Math.round(paceData.ahtRequired);
        });

           setSummary(response)
         })
         .catch(err => {
          setSummary(null);
        })
   
     }, [])


     let username = "hvinu@amazon.com";
     let password = "rTZdueSZ1WMEepk0Nkfa-PVRfwfODfcoqIZFebQI7";
     let headers = new Headers();
     const proxyurl = "https://cors-anywhere.herokuapp.com/";
     headers.append('Authorization', 'Basic' + base64.encode(username + ":" + password));
 //    headers.append('Authorization','Basic aHZpbnVAYW1hem9uLmNvbTpyVFpkdWVTWjFXTUVlcGswTmtmYS1QVlJmd2ZPRGZjb3FJWkZlYlFJNw==')
  //   headers.append('Access-Control-Allow-Origin','*');
  //   headers.append('Cookie', 'tr_session=02632d0e-6a60-4628-bf48-136fec0a30dd');
         console.log("inside the call");
         useEffect(() => {
             fetch( "https://testrail-avsqa.avs.amazon.dev/index.php?/api/v2/get_tests/7371", // index.php?/api/v2/get_tests/7371
             {mode:'no-cors'},
             {method:'GET', 
             credentials: 'include',
           headers: headers,
            }
         )
      //   fetch("https://magnusdashboard.aka.corp.amazon.com/" + "testersSkillsDataGrouped")  {mode:'no-cors'},
            
          
             .then(resp => resp.json())
             .then(resp => {
              console.log("this shit expected"); 
               console.log(resp); 
             //   resp.testersPaceData.forEach(function (paceData) {
                
             // });
       
             //    setData(resp.testersPaceData)
             //    setRefreshTime(resp.lastUpdated);
             })
             .catch(err => {
                 console.log("failed");
             })
         }, [])

  const downloadExcel=(dump)=>{
    const newData=dump.map(row=>{
      delete row.tableData
      return row
    })
    const workSheet=XLSX.utils.json_to_sheet(newData)
    const workBook=XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook,workSheet,"dump")
    XLSX.write(workBook,{bookType:"xlsx",type:"binary"})
    XLSX.writeFile(workBook,"PaceData.xlsx")
  }

  const MyNewTitle = ({ text, variant }) => (
    <Typography
      variant={variant}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color:"#ACAAAA",
        fontWeight: 900
      }} >
      {text}
    </Typography>
  );

  return (
    <div >
    
      {(() => {
        if (refreshTime!=null) {
          return (
            <p className="refresh" >Last refreshed at {refreshTime.slice(0,6).join(":")} IST</p> 
          )
        } 
        else {
          return (<p className="serverFault" >Skills data from CIC_Databse is NOT ACCESSIBLE... Please reach out to Blr-QA-Tools(@alexa-tools-titans) </p>)
        }
      })()}
      

     <div className="card1"> 
      <MaterialTable 
       title={<MyNewTitle variant="h5" text="Pace Summary by Teams" />}
        tableRef={tableRef}
        icons={tableIcons}
        data={summary}
        columns={summaryColumns}
        actions={[
          { icon:()=><DownloadIcon />,
          tooltip:"Export to Excel",
           onClick:()=>downloadExcel(summary),
          isFreeAction:true}
        ]}
        options={{
          maxBodyHeight: 700,
          maxBodyWidth: 48,
          headerStyle: {
            backgroundColor: 'rgb(15, 32, 78)',
            color: '#FDFEFE',
            fontSize: 15,
            height:4,
            width: 20,
            maxWidth: 150,
            paddingTop:2,
            paddingBottom:2,
            fontWeight: "bold", 
            textAlign:"center",
            position: 'sticky', top: 0
          },
          rowStyle: x => {
            if (x.tableData.id % 2) {
                return {backgroundColor: "white"}
            }
            else {
              return {backgroundColor: "#f0e8ec"}
          }
        },
          cellStyle: {
            fontWeight: "500",
            fontSize: 13, 
            textAlign:"center",
            paddingTop:3,
            paddingBottom:3,
            width: 20,
            maxWidth: 150
          },
          paging: false,
          search: true,
          sorting: true,
          tableLayout:'auto'
        }}
      />
      </div>
      <p>..</p>
      <p>..</p> 

      <div className="card1"> 
      <MaterialTable 
        title={<MyNewTitle variant="h5" text="Testers Pace Data" />} 
        tableRef={tableRef}
        icons={tableIcons}
        data={testersData}
        columns={columns}
        actions={[
          { icon:()=><DownloadIcon />,   
          tooltip:"Export to Excel",
           onClick:()=>downloadExcel(testersData),
          isFreeAction:true}
        ]}
        options={{
          maxBodyHeight: 700,
          maxBodyWidth: 48,
          headerStyle: {
            backgroundColor: 'rgb(15, 32, 78)',
            color: '#FDFEFE',
            fontSize: 15,
            height:4,
            width: 20,
            maxWidth: 150,
            paddingTop:2,
            paddingBottom:2,
            fontWeight: "bold", 
            textAlign:"center",
            position: 'sticky', top: 0
          },
          rowStyle: x => {
            if (x.tableData.id % 2) {
                return {backgroundColor: "white"}
            }
            else {
              return {backgroundColor: "#f0e8ec"}
          }
        },
          cellStyle: {
            fontWeight: "500",
            fontSize: 13, 
            textAlign:"center",
            paddingTop:3,
            paddingBottom:3,
            width: 20,
            maxWidth: 150
          },
          paging: false,
          search: true,
          sorting: true,
          tableLayout:'auto'
        }}
      />
      </div>
      <p> </p>
      <a className="bug" href="https://tiny.amazon.com/c0uxzrr3" target='_blank'>    Report an Issue</a>
    
    </div>
  );
}

export default Manager;